(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash/fp"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash/fp"], factory);
	else if(typeof exports === 'object')
		exports["futil-js"] = factory(require("lodash/fp"));
	else
		root["futil-js"] = factory(root["lodash/fp"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 